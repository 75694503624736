$primaryColor: #8F0E1F;

#hero{
    padding: 50px;
    height: 60vh;
    background-size: cover;
    background-position: center;
    display: flex;
    div{
        z-index: 1;
        margin: auto;
        max-width: 1200px;
        .header-1-div{
            display: flex;
            width: 100%;
            margin-bottom: 30px;
        }

        h1, h2{
            font-variant: small-caps;
            text-align: center;
            padding: 20px;
            font-size: 2.5em;
            font-weight: normal;
        }

        h1{
            background-color: white;
            display: inline-block;
            margin: auto;
            color: gray;
        }
    
        h2{
            background-color: $primaryColor;
            color: white;
        }
    }
}

@media screen and (max-width: 600px) {
    #hero{
        font-size: 10px;
        div{
            h1, h2{
                
            }
        }
    }
  }