.collection-container{
    max-width: 600px;
}

.year-btn{
    width: 100%;
    margin: auto;
    display: block;
    background-color: var(--main-red);
    color: #FAFAFA;
    border-radius: 5px;
    border: 1px solid lightgray;
    margin-top: 10px;
    padding: 10px;
}

.year-btn:hover{
    background-color: var(--main-red-darker);

}
  
    .collection-items-list{
        margin: 0;
        padding: 0;
        list-style-type: none;
    
        li{
            border-bottom: 1px solid lightgray;
            padding: 10px;
        }
        li:last-child{
            border-bottom: none;
        }
        
        li>a{
            color: rgb(68, 68, 68);
            text-decoration: none;
        }
        li>a:hover{
            color: var(--main-red);
        }
    }
