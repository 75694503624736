$primaryColor: #8F0E1F;

#greet{
    background-color: $primaryColor;
    min-height: 200px;
    padding: 40px;
    max-width: 800px;
    width: 80%;
    margin: 50px auto 50px auto;

    color: #FAFAFA;

    h2{
        font-family: 'EB Garamond', serif;
        font-variant: small-caps;
        font-weight: normal;
    }
    hr{
    }
    p{
        text-align: justify;
        font-weight: 300;
    }
    a{
        text-decoration: none;
    }
    a>h3{
        text-align: center;
        text-transform: uppercase;
        color: #FAFAFA;
        text-decoration: none;
        font-family: 'EB Garamond', serif;
        font-weight: lighter;
        font-size: 1.3rem;
    }
}