.publicationAuthorSelectHolder{
    max-width: 1000px;
    margin: auto;
    padding: 0px 20px;
    display: flex;
    justify-content: center;
    
    select{
        padding: 5px;
        margin: 0;
    }
}

.publication-section-holder{
    margin-bottom: 40px;
    padding: 0;
    max-width: 1000px;
    margin: auto;
    .publication-section{
        padding: 0px 0px 30px 0px;
        margin: 20px;

        transition: 1s;
        height: 100%;
        border: 1px solid lightgray;
        border-radius: 5px;
        background-color: #FAFAFA;

        position: relative;

            a{
                text-decoration: none;
            }
            
            h2{
                padding: 20px;
                font-size: 1.3rem;
                font-variant: small-caps;
                font-weight: normal;
                color: rgb(70, 70, 70);
                font-family: 'Garamond', serif;
            }

            h2:hover{
                color: var(--main-red-darker);
            }

            p{
                padding: 0px 20px;
                font-size: 1rem;
                color: gray;
                font-family: 'Garamond', serif;
            }

            a.more-link{
                position: absolute;
                bottom: 10px;
                right: 20px;
                color: var(--main-red);
                padding-bottom: 5px;
                font-style: italic;
                font-weight: 300;
            }

            a.more-link:hover{
                color: var(--main-red-darker);
            }

            div.author{
                font-variant: small-caps;
                background-color: var(--main-red);
                color: white;
                padding: 5px 20px;
                font-size: 1rem;
            }


    }
}