#media-image{
    .description{

        h3{
            font-variant: small-caps;
            font-weight: normal;
            text-align: center;
            margin-bottom: 20px;
        }

        p{
            color: rgb(100, 100, 100);
            text-align: justify;
        }
    }


    .image-link-holder{
        padding: 10px;
        div{
            border-radius: 5px;
            border: 1px solid lightgray;
            background-color: white;
            padding: 20px;
            
            a{
                color: var(--main-red);
                text-align: center;
                display: block;
            }
        }
    }
}

#video-holder{
    h3{
        font-variant: small-caps;
        font-weight: normal;
        text-align: center;
        margin-bottom: 20px;
    }
    padding: 20px;
    div{
        border: 1px solid lightgray;
        padding: 10px;
        .video-frame{
            width: 100%;
            height: 300px;
        }


    }

}

.soundCard{
    padding: 10px;
}

.soundCard>div{
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    padding: 20px;
    padding-bottom: 50px;

    img{
        float:left;
        max-width: 200px;
        width: 40%;
        margin-right: 20px;
    }
    min-height: 200px;
    border: 1px solid rgb(185, 185, 185);
    background-color: white;
    border-radius: 5px;

    .title>a{
        text-decoration: none;
        color: var(--main-red);
        display: block;
        position: static;
        margin-bottom: 20px;
        font-size: 1.5rem;
    }

    .link{
        position: absolute;
        bottom: 10px;
        right: 10px;
    }

    @media only screen and (max-width: 600px) {
        img{
            float: none;
            display: block;
            margin: auto;
            margin-bottom: 10px;
        }

        .title>a{
            font-size: 1.3rem;
            text-align: center;
        }

        .link{
            display: block;
            margin-top: 30px;
            position: static;
            text-align: center;
            a{
                display: block;
            }
        }

        padding-bottom: 20px;
    }

}