#my-navbar {
  position: fixed;
  top: 0px;
  z-index: 99;
  width: 100%;
  background-color: white;

  div {
    max-width: 1000px;
    margin: auto;
    ul {
      display: flex;
      margin: 0;
      padding: 20px 20px;
      justify-content: space-between;
      max-width: 100%;
      li {
        display: inline;
        text-transform: uppercase;

        a {
          color: gray;
          text-decoration: none;
          transition: 0.5s;
          font-family: "EB Garamond", serif;
        }

        a:hover {
          color: rgb(80, 80, 80);
        }
      }
    }
  }
}

#my-mobile-navbar {
  position: fixed;
  top: 0px;
  z-index: 99;
  width: 100%;
  background-color: white;
  height: 60px;
  div {
    max-width: 1000px;
    margin: auto;
    ul {
      margin: 0;
      padding: 20px 20px;
      justify-content: space-between;
      max-width: 100%;
      li {
        text-transform: uppercase;

        a {
          color: gray;
          text-decoration: none;
          transition: 0.5s;
        }

        a:hover {
          color: rgb(80, 80, 80);
        }
      }
    }
  }

  .mobile-navbar {
    position: absolute;
    z-index: 99;
    background-color: white;
    width: 100vw;
    
    transition: 0.5s;

    ul {
      li {
        display: flex;
        align-items: center;
        justify-content: center;
        height: calc(100vh / 8);

        a {
          font-family: "EB Garamond", serif;
        }
      }
    }
  }
  .open {
    height: 100vh;
  }
  .close {
    height: 0px;
    overflow: hidden;
    ul li a {
      color: rgba(255, 0, 0, 0);
    }
  }
}
