#lifeway{

    .container{
        max-width: 1000px;
    }

    h2.first_letter{
        font-weight: normal;
        border-bottom: 1px solid var(--main-red);
        color: var(--main-red);
        padding-bottom: 10px;
        margin-top: 20px;
    }

    .row{
        padding-top: 50px;

        .nameBtn{
            display: block;
            text-align: left;
            border-radius: 0px;
            margin-bottom: 10px;
            border: none;
            font-size: 1.2rem;
        }

        .nameBtn:hover{
            text-decoration: underline;
        }

        .lifeway-data{
            font-variant: small-caps;
        }

        h3.lifeway-data{
            font-weight: normal;
            font-size: 1rem;
        }

        img.lifeway-data{
            width: 100%;
            display: block;
            max-width: 300px;
            margin: auto;
        }

        .content{
            padding-left: 20px;
            border-left: 1px solid gray;
            p{
                font-family: 'EB Garamond', serif;
                font-size: 1rem;
                color: rgb(80, 80, 80);
            }

            h1,h2,h3,h4,h5,h6{
                font-variant: small-caps;
                padding-bottom: 20px;
            }
        }

        @media (max-width: 960px){
            .content{
                border: none;
                padding-left: 0px;
                padding-top: 20px;
                text-align: justify;
            }
        }
    }
}