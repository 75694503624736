footer{
    margin-top: 50px;
    background-size: cover;
    padding: 0px;
    margin: 0px;
    background-image: url("../../img/footer/footer.jpg");
    background-position: center;
    
    .container{
        padding: 30px;

        img.footer-logo{
            width: 100%;
            max-width: 150px;
            display: block;
            margin: auto;
        }
    }

    a{
        color: #AAA;
    }

    a:hover{
        color: white;
        img{
            filter: invert(100%);
        }
    }

    ul{
        list-style-type: none!important;
        margin: auto;
    }

    li{
        padding-bottom: 10px;
    }

    li>a>img{
        filter: invert(80%);
        width: 15px;
        margin-right: 10px;
    }

    .footer-bottom{
        background-color: #222222;
        padding-bottom: 40px;
        p{
            color: rgb(226, 226, 226);
            font-weight: lighter;
            text-align: center;
            width: 100%;
            font-size: 0.9rem;
            padding: 0px;
            margin: 0px;
        }

        .triangle{
            width: 0px;
            height: 0px;
            border-left: 50px solid transparent;
            border-bottom: 40px solid #222222;
            border-right: 50px solid transparent;
            position: relative;
            left: 50%;
            top: -40px;
            display: block;
            transform: translate(-50%, 0%);
        }
    }
}