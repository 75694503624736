
:root{
  --main-red: #8E0E1F;
  --main-red-darker: rgb(110, 11, 24);
}

body {
  scroll-margin-top: 200px;
  font-family: 'Garamond', serif;
  color: rgb(41, 41, 41);
  margin: 0;
  padding-top: 60px;
}

.content-holder{
  width: 100%;
  padding: 50px;
  background-color: #ECF0F1;
  min-height: calc(100vh - 350px);
}

p, button, a{
  font-family: 'Roboto', sans-serif;
}

.page-message{
  margin: auto;
  font-size: 1.5rem;
  text-align: center;
  color: gray;
}