.collection_category-section-holder{
    margin-bottom: 20px;
    width: 100%;
    .category-section{
        padding: 5px;
        margin: 20px;
        display: flex;
        transition: 1s;
        height: 100%;
        border: 1px solid lightgray;
        border-radius: 5px;
        background-color: #FAFAFA;
        width: 100%;

        .img-holder{
            width: 30%;
            img{
                width: 100%;
            }
        }

        .img-holder:hover{
            transition: 1s;
            img{
                filter: opacity(0.5);
            }
            background-color: var(--main-red);
        }

        .description-holder{
            padding: 20px;
            position: relative;
            width: 70%;

            a{
                text-decoration: none;
            }

            h2{
                font-size: 1.5rem;
                font-variant: small-caps;
                font-weight: normal;
                color: rgb(70, 70, 70);
                font-family: 'EB Garamond', serif;
            }

            h2:hover{
                color: var(--main-red-darker);
            }



            p{
                font-size: 1.1rem;
                color: gray;
                font-family: 'EB Garamond', serif;
            }

            a.more-link{
                position: absolute;
                bottom: 0px;
                right: 10px;
                color: var(--main-red);
                padding-bottom: 5px;
                text-decoration: underline;
                font-weight: normal;
            }

            a.more-link:hover{
                color: var(--main-red-darker);
            }

            @media screen and (max-width: 1200px) {
                h2{
                    font-size: 1.3rem;
                }
                a.more-link{
                    font-size: 0.8rem;
                }
            }

        }
    }
}