.title-holder{
    h1{
        font-variant: small-caps;
        font-weight: normal;
        text-align: center;
        font-size: 1.8rem;
    }

    .flower{
        font-size: 3rem;
        text-align: center;
        color: var(--main-red);
    }
}