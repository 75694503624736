.category-card{
    
    border: 1px solid rgb(189, 189, 189);
    padding: 0px 20px 20px 20px;
    position: relative;
    background-color: white;

    margin-top: 100px;

    .icon{
    width: 100px;
    height: 100px;
    background-color: var(--main-red);
    position: relative;
    top: -50px;
    left: 50%;
    transform: translate(-50%, 0%);
    margin: 0px;
    padding: 15px;
    display: flex;
        img{
            display: block;
            width: 70px;
            height: 70px;
            margin: auto;
            -webkit-filter: invert(100%);
            filter: invert(100%);
        }
    }

    .card-title{
        margin-top: -20px;
        margin-bottom: 20px;
        font-variant: small-caps;
        text-align: center;
        font-weight: normal;
        font-size: 1.5rem;
        display: block;
        color: black;
        text-decoration: none;
        font-family: 'EB Garamond', serif;
    }

    .card-title:hover{
        color: var(--main-red);
    }
    
    p{
        color: gray;
        text-align: center;
    }

    .button{
        padding: 10px;
        display: inline-block;
        margin: auto;
        border: none;
        background-color: var(--main-red);
        color: white;
        text-decoration: none;
        position: relative;
        left: 50%;
        transform: translate(-50%, 0%);
    }

    button:hover{
        background-color: var(--main-red-darker);
    }
}

.category-card:hover{
    border: 1px solid black;
    .icon{
        background-color: var(--main-red-darker);
    }

}