#priestMemoPage{

    .container{
        max-width: 800px;


        .lived{
            font-weight: normal;
            font-variant: small-caps;
            text-align: center;
            font-size: 1.2rem;
        }
    }


    img{
        max-width: 500px !important;
        display: block;
        margin: auto;
        width: 100% !important;
        height: auto !important;
    }
}