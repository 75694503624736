#priests {
  .introduction {
    max-width: 800px;
    margin: auto;
    h3 {
      font-variant: small-caps;
      text-align: center;
      font-weight: normal;
      margin-bottom: 20px;
    }
    p {
      color: rgb(100, 100, 100);
      text-align: justify;
    }

    p > a {
      color: var(--main-red);
    }
    p > a:hover {
      color: var(--main-red-dark);
    }
  }

  .container {
    max-width: 1000px;

    .resultHolder {
      max-width: 800px;
      margin: auto;
      padding: 10px;
      border: 1px solid rgb(200, 200, 200);
      background-color: #FAFAFA;
      .letterBtn,
      .letterBtn:hover,
      .letterBtn:focus,
      .letterBtn:active {
        width: 100%;
        border: none;
        border-radius: 0;
        text-align: left;
      }

      .letterBtn:hover{
          background-color: white;
          transition: 0.2s;
      }

      .priestLinkHolder {
        a {
          color: gray;
          padding: 5px;
          display: inline-block;
        }

        a:hover{
            color: var(--main-red);
        }
      }
    }
  }
}
