.category-section-holder{
    margin-bottom: 40px;

    .category-section{
        
        padding: 20px;
        margin: 0px;
        display: flex;
        transition: 1s;
        height: 100%;
        border: 1px solid lightgray;
        border-radius: 5px;
        background-color: #FAFAFA;

        .img-holder{
            width: 30%;
            img{
                width: 100%;
            }
        }
        
        .description-holder{
            box-sizing: border-box;
            position: relative;
            padding-left: 25px;
            padding-bottom: 20px;
            width: 70%;

            a{
                text-decoration: none;
            }

            h2{
                font-size: 1.3rem;
                font-variant: small-caps;
                font-weight: normal;
                color: rgb(70, 70, 70);
                font-family: 'EB Garamond', serif;
            }

            h2:hover{
                color: var(--main-red-darker);
            }

            p{
                font-size: 1.1rem;
                color: gray;
                font-family: 'EB Garamond', serif;
            }

            a.more-link{
                position: absolute;
                bottom: -10px;
                right: 0px;
                color: var(--main-red);
                padding-bottom: 5px;
                text-decoration: underline;
            }

            a.more-link:hover{
                color: var(--main-red-darker);
            }

        }
    }
}